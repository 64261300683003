/* Hero Section */
.hero-section {
  position: relative;
  background-size: cover;
  background-position: center;
  /* min-height: 100vh; */
  display: flex;
  align-items: center;
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
}

/* Black Overlay */
.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

/* Left Content */
.hero-content {
  position: relative;
  z-index: 2;
}

.hero-title {
  font-size: 2.3rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.hero-text {
  font-size: 1.1rem;
  margin-bottom: 20px;
}
.hero-list {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}
.hero-list li {
  font-size: 1rem;
  margin-bottom: 5px;
}
/* Button */
.hero-button {
  display: inline-block;
  padding: 12px 25px;
  background: red;
  color: white;
  text-decoration: none;
  font-size: 18px;
  border-radius: 5px;
  transition: 0.3s;
}

.hero-button:hover {
  background: darkred;
}

/* Right Form */
.hero-form-container {
  position: relative;
  z-index: 2;
}

.form {
  background-color: #fff;
  display: block;
  padding: 1rem;
  max-width: 350px;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.form-title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-align: center;
  color: #000;
}

.input-container {
  position: relative;
}

.input-container input,
.form button {
  outline: none;
  border: 1px solid #e5e7eb;
  margin: 8px 0;
}

.input-container select,
.input-container input {
  background-color: #fff;
  padding: 0.6rem;
  padding-right: 3rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 316px;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #ccc;
  appearance: none;
  margin: 8px 0;
}

.input-container {
  position: relative;
  /* margin-bottom: 0.6rem; */
}

.input-container select {
  cursor: pointer;
}

.input-container span {
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  place-content: center;
}

.submit {
  display: block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: red;
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  width: 100%;
  border-radius: 0.5rem;
  text-transform: uppercase;
  cursor: pointer;
}
.signup-link {
  color: #6b7280;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
}

.signup-link a {
  text-decoration: underline;
}

.sub-services-section {
  padding: 60px 0;
  background-color: #ffecec63;
}

.sub-services-title {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.service-card {
  border-radius: 15px;
  border: 1px solid #e5e5e5;
  transition: all 0.5s ease-in-out;
}

.service-card:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transform: scale(1.05);
}

.service-title {
  font-size: 20px;
  font-weight: bold;
  color: #222;
}

.service-points li {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.primary-heading-container {
  text-align: center;
  margin-bottom: 20px;
}

.section-primary-heading {
  font-size: 2rem;
  font-weight: bold;
}

.heading-underline {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}

.line {
  width: 50px;
  height: 3px;
  background-color: #ff5252;
}

.home-icon {
  font-size: 1.8rem;
  color: #ff5252;
  margin: 0 10px;
}

.error {
  color: red;
  font-size: 12px;
  margin-bottom: 5px;
  margin-left: 5px;
}

/* why choose us */

section.why-choose-us {
  text-align: center;
  padding: 60px 20px;
  background: linear-gradient(
    to right,
    #f9f9f9,
    #ffffff
  ); /* Subtle gradient background */
}

.why-choose-us h2 {
  font-size: 32px;
  color: #222;
  font-weight: bold;
  margin-bottom: 40px;
  position: relative;
}

.why-choose-us h2::after {
  content: "";
  display: block;
  width: 100px;
  height: 4px;
  background: #ff5252;
  margin: 10px auto 0;
  border-radius: 2px;
}

.features-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.feature-card {
  background: #ffffff;
  border: 2px solid #ff5252;
  border-radius: 12px;
  padding: 20px;
  width: 180px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.feature-card:hover {
  background: #ff5252;
  color: white;
  transform: translateY(-5px);
}

.feature-icon {
  font-size: 38px;
  margin-bottom: 12px;
  color: #ff5252;
}

.feature-card:hover .feature-icon {
  color: white;
}

.feature-title {
  font-size: 18px;
  font-weight: bold;
}

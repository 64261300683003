.css-5r88sx-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
}

.autocomplete {
  position: relative;
  display: inline-block;
  width: 100%;
}

.autocomplete-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  outline: none;
  transition: placeholder 0.3s ease;
  border-radius: 25px;
}

.autocomplete-list {
  position: absolute;
  z-index: 1000;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: block;
}

.autocomplete-item {
  padding: 10px;
  cursor: pointer;
}

.autocomplete-item:hover {
  background-color: #f2f2f2;
}

.autocomplete-link {
  text-decoration: none;
  color: #000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Allow wrapping */
  text-decoration: none;
  color: black;
  width: 100%; /* Ensure the link takes the full width */
  font-size: 14px;
}

.exclusive-property-section {
  background-color: #e084840f;
}

.swiper-button-prev,
.swiper-button-next {
  background-color: #db282f; /* Red background */
  color: #fff; /* White arrow color */
  font-size: 15px;
  border-radius: 50%;
  width: 30px !important; /* Decrease width */
  height: 30px !important; /* Decrease height */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  transition: background-color 0.3s;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 15px !important; /* Adjust icon size */
  font-weight: bold;
  color: #fff; /* White arrow color */
}

.swiper-button-prev {
  left: 10px; /* Adjust the left position as needed */
}

.swiper-button-next {
  right: 10px; /* Adjust the right */
}

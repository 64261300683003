.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}
.filter-drawer-top {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  align-items: center;
}
/* From Uiverse.io by Creatlydev */
.button {
  line-height: 1;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.35em;
  padding: 0.75em 1em;
  padding-right: 1.25em;
  color: #fff;
  border: 1px solid transparent;
  font-weight: 700;
  border-radius: 2em;
  font-size: 1rem;
  box-shadow: 0 0.7em 1.5em -0.5em hsla(249, 62%, 51%, 0.745);
  transition: transform 0.3s;

  background: linear-gradient(
    90deg,
    rgba(77, 54, 208, 1) 0%,
    rgba(132, 116, 254, 1) 100%
  );
}

.button__icon {
  width: 1.5em;
  height: 1.5em;
}

.button:hover {
  border-color: #f4f5f2;
}

.button:active {
  transform: scale(0.98);
  box-shadow: 0 0.5em 1.5em -0.5em hsla(249, 62%, 51%, 0.745);
}

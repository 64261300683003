.login-button {
  position: relative;
  background-color: rgb(230, 34, 77);
  border-radius: 5px;
  /* box-shadow: rgb(121, 18, 55) 0px 4px 0px 0px; */
  padding: 15px;
  background-repeat: no-repeat;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  height: 49px;
  color: #fff;
  border: none;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  overflow: hidden;
}

.login-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
.login-button:disabled::before {
  background-color: #cccccc;
  cursor: not-allowed;
}

.login-button::before {
  content: "";
  background-color: rgb(248, 50, 93);
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: width 700ms ease-in-out;
  display: inline-block;
}

.login-button:hover::before {
  width: 100%;
}

.otp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
}

h2 {
  margin-bottom: 20px;
}

.otp-inputs {
  display: flex;
  gap: 10px;
}

.otp-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
}

.otp-input:focus {
  border-color: #007bff;
}

.otp-submit {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.otp-submit:hover {
  background-color: #0056b3;
}

.timer {
  font-size: 18px;
  color: #ff0000;
  margin-top: 20px;
}

.mydict div {
  display: flex;
  flex-wrap: wrap;
  margin-top: -3px;
  justify-content: center;
}

.mydict1 {
  margin-right: 2rem;
  margin-bottom: 13px;
}

.mydict1 input[type="radio"],
.mydict1 input[type="checkbox"] {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.mydict1 input[type="radio"]:checked + span,
.mydict1 input[type="checkbox"]:checked + span {
  box-shadow: 0 0 0 0.0625em #191919;
  background-color: #443d3d;
  z-index: 1;
  color: #ffffff;
}

.mydict1 input[type="radio"]:checked + dev {
  border-radius: 20px;
  border: 0.5px solid var(--BG-Color-5, #db282f);
  background: #ffe3e3;
}

label span,
label dev {
  display: block;
  cursor: pointer;
  background-color: #fff;
  padding: 0.375em 0.75em;
  position: relative;
  margin-left: 0.0625em;
  box-shadow: 0 0 0 0.0625em #141415;
  letter-spacing: 0.05em;
  color: #141414;
  text-align: center;
  transition: background-color 0.5s ease;
}

.border1 {
  border-radius: 20px 20px 20px 20px;
  text-transform: capitalize;
  max-height: 33px;
}
.border3 {
  border-radius: 20px 20px 20px 20px;
  text-transform: capitalize;
}
.border2 {
  border-radius: 20px 20px 20px 20px;
  text-transform: capitalize;
  max-height: 24px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(224, 222, 222, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mydictt label span {
  display: block;
  cursor: pointer;
  background-color: #fff;
  padding: 0.375em 0.75em;
  position: relative;
  margin-left: 0.0625em;
  box-shadow: 0 0 0 0.0625em #141415;
  letter-spacing: 0.05em;
  color: #141414;
  text-align: center;
  transition: background-color 0.5s ease;
}

.mydictt label dev {
  display: block;
  cursor: pointer;
  background-color: #fff;
  padding: 0.375em 0.75em;
  position: relative;
  margin-left: 0.0625em;
  box-shadow: 0 0 0 0.0625em #141415;
  letter-spacing: 0.05em;
  color: #141414;
  text-align: center;
  transition: background-color 0.5s ease;
  padding-top: 0.001em;
}

/* For Firefox */
.custom-scrollbar {
  scrollbar-width: thin; /* Makes the scrollbar thin */
  scrollbar-color: red #fff; /* Sets scrollbar thumb color and track color */
}

/* For WebKit browsers (Chrome, Safari) */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounds the corners of the scrollbar thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #ff7675; /* Color of the scrollbar thumb on hover */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #ff7675; /* Color of the scrollbar thumb on hover */
}

/* styles.css */
.property-main-point {
  width: 100%;
  overflow: auto;
  margin: 10px 8px; /* Equivalent to mx: 1 in MUI */
  font-size: 12px;
  white-space: nowrap;
  z-index: 2;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  cursor: grab; /* Indicate draggable area */
}

.property-main-point::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.property-main-point-box {
  background-color: #eaeaea;
  display: inline-block;
  padding: 6px 12px; /* Equivalent to px: 1.5 and py: 0.8 in MUI */
  border-radius: 8px;
  margin-right: 8px; /* Equivalent to mr: 1 in MUI */
}
